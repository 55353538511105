<template>
  <div>
    <b-button
      :id="id_button"
      size="sm"
      variant="primary"
      :title="text_button"
      v-b-tooltip.topleft.v-secondary.noninteractive="{
        title: tooltip_text ? tooltip_text : '',
      }"
      @click="togglePopover"
      class="noprint"
      :class="custom_class"
      :disabled="disabled_button"
    >
      <!-- <img
        v-if="show_icon"
        src="@/assets/new-page/icon-download.svg"
        alt="Icono Descargar"
      /> -->
      <b-icon :icon="isOpen ? 'chevron-up' : 'chevron-down'"></b-icon>
      <span> Descargar </span>
      <!-- <b-icon icon="three-dots-vertical"></b-icon> -->
    </b-button>
    <b-popover
      :id="`popover-${id_button}`"
      :target="id_button"
      class="popover-wrapper"
      placement="bottom"
      triggers="click blur"
      @shown="isOpen = true"
      @hidden="isOpen = false"
    >
      <div class="d-flex flex-column popover-buttons-container">
        <slot name="popover-container-buttons"></slot>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "ContainerButtonsDownload",
  props: {
    id_button: {
      type: String,
      default: "",
    },
    text_button: {
      type: String,
      default: "",
    },
    tooltip_text: {
      type: String,
      required: true,
    },
    // click_button: {
    //   type: Function,
    //   required: false,
    // },
    size: {
      type: String,
      default: "sm",
    },
    // variant: {
    //   type: String,
    //   default: "secondary",
    // },
    show_icon: {
      type: Boolean,
      default: true,
    },
    custom_class: {
      type: String,
      default: "",
    },
    disabled_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_active: false,
      isOpen: false,
    };
  },
  methods: {
    togglePopover() {
      this.isOpen = !this.isOpen;
    },
    // handleClick() {
    //   // Si se proporciona una función de click, se ejecuta
    //   if (this.click_button) {
    //     this.click_button();
    //   } else {
    //     this.$emit("click");
    //   }
    // },
  },
  mounted() {},
};
</script>

<style scoped>
.btn-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333 !important;
  border: 1px solid #bdbdbd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
.btn-toggle:hover,
.btn-toggle:focus {
  background-color: #f7f9fa;
  color: #0056b3 !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.b-icon {
  font-size: 14px;
}
.popover.b-popover >>> .popover-body {
  padding: 0.3rem !important;
  max-width: 500px !important;
  overflow: hidden;
}
.popover-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Espaciado entre botones */
}
</style>